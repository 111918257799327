import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment';

const chart = () => {


  if(document.querySelector('.myChart')) {

    var getAllCtx = document.querySelectorAll('.myChart')

    getAllCtx.forEach((elem) => {

      var ctx = elem.getContext('2d');
      var datasets = [];
      var labels = [];
      var counter = 0;
      var borderColor = ['#1C174F', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];
      var typeGraph = (typeof ctx.canvas.dataset.type_graph === 'undefined') ? "line" : ctx.canvas.dataset.type_graph;
      var language = (typeof ctx.canvas.dataset.language === 'undefined') ? "us" : ctx.canvas.dataset.language;

      moment.locale(language);

      if (typeGraph === "line") {
        if (ctx.canvas.dataset.label === undefined) {
          JSON.parse(ctx.canvas.dataset.data).forEach((array) => {
            datasets.push(`{
              "data": [${array}],
              "fill": true,
              "borderColor": "${borderColor[counter]}",
              "tension": 0.4,
              "backgroundColor": "${borderColor[counter]}40"}`);
            counter = counter + 1;
          });
        } else  {
          JSON.parse(ctx.canvas.dataset.data).forEach((array) => {
            datasets.push(`{
              "label": "${JSON.parse(ctx.canvas.dataset.label)[counter]}",
              "data": [${array}],
              "fill": true,
              "borderColor": "${borderColor[counter]}",
              "tension": 0.4,
              "backgroundColor": "${borderColor[counter]}40"}`)
            console.log(JSON.parse(ctx.canvas.dataset.label)[counter])
            counter = counter + 1;
          });
        }

        datasets = datasets.map(dataset => JSON.parse(dataset));

        // JSON.parse(ctx.canvas.dataset.data).forEach(array =>
        //   datasets.push(`{"data": [${array}], "fill": false, "borderColor": "#1C174F", "tension": 0.1}`)
        // );

        console.log(datasets)


        var myChart = new Chart(ctx, {
          type: typeGraph,
          data: {
              labels: JSON.parse(ctx.canvas.dataset.labels),
              datasets: datasets
                // JSON.parse(datasets[0])
            },
          options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                  legend: JSON.parse(ctx.canvas.dataset.legend)
              },

              scales: {
                  y: {
                      type: 'linear',
                      min: 0,
                      // max: 100
                  },

                  x: {
                      type: 'time',
                      time: {
                        unit: ctx.canvas.dataset.time_unit,
                        displayFormats: {
                          week: 'W',
                          day: 'dddd DD MMMM',
                        }
                      },
                      ticks: {
                        callback: function (value) {
                          if (ctx.canvas.dataset.time_unit === "week") {
                            return 'W' + value;
                          } else {
                            return value;
                          }
                        }
                      }
                      // min: new Date('2019-01-01').valueOf(),
                      // max: new Date('2019-12-31').valueOf()
                  },
              }
          }
        })
      }
      else if(typeGraph === "doughnut") {

        console.log(JSON.parse(ctx.canvas.dataset.data))

        // count number of elem in the array
        const nbElem = JSON.parse(ctx.canvas.dataset.data).length;

        let counter = 0;
        let borderColorAll = []
        if (ctx.canvas.dataset.color === undefined) {
          while (counter < nbElem) {
            borderColorAll.push(`"${borderColor[counter]}"`)
            counter = counter + 1
          }
        } else {
          borderColorAll = [ctx.canvas.dataset.color]
        }

        if (ctx.canvas.dataset.labels !== undefined) {
          var labels = JSON.parse(ctx.canvas.dataset.labels)
        }

        if (ctx.canvas.dataset.half_doughnut !== undefined) {
          var circumference = 180;
          var rotation = 270;
          var borderRadius = 1000;

          if (ctx.canvas.dataset.indicator_gauge !== undefined) {

            var tooltipEnabled = false;

            datasets.push(`{
              "data": ${ctx.canvas.dataset.data},
              "backgroundColor": [
                "rgba(0,0,0,0)",
                "#1C174F",
                "rgba(0,0,0,0)"
              ],
              "cutout": "75%"
            }`);

            datasets.push(`{
              "data": [33, 33, 33],
              "backgroundColor": ["#f5557f", "#d2ae02", "#51c7a0"],
              "cutout": "90%",
              "borderWidth": "0"
            }`);
          } else {

            var borderRadius = [1000, 0]

            datasets.push(`{
              "data": ${ctx.canvas.dataset.data},
              "backgroundColor": [${borderColorAll}],
              "cutout": "90%"
            }`);

          }

          datasets = datasets.map(dataset => JSON.parse(dataset));
        } else {
          var circumference = 360;
          var rotation = 0;

          datasets.push(`{
            "data": ${ctx.canvas.dataset.data},
            "backgroundColor": [${borderColorAll}],
            "cutout": "80%"
          }`);

          datasets = datasets.map(dataset => JSON.parse(dataset));

        }

        // Use more for doughnut


        // centerTextDoughnut plugin
        const centerTextDoughnut = {
          id: 'centerTextDoughnut',
          afterDatasetsDraw(chart, args, options) {
            const { ctx, data, chartArea: {top, bottom, left, right, width, height} } = chart;


            if (ctx.canvas.dataset.to_center !== undefined) {
              var text = ctx.canvas.dataset.to_center
            } else {
              var text = ""
            }

            if (ctx.canvas.dataset.half_doughnut !== undefined) {
              var x = width / 2;
              var y = height - (height / 2) ;
            } else {
              var x = width / 2;
              var y = height / 2;
            }

            // TO IMPROVE
            // ctx.canvas.dataset.to_center should look like that
            // [
            //   [text1, lineheight,["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text2, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text3, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ]
            // ]

            var lineheight = 50;
            var lines = text.split('\n');
            var font_other = "ctx.font = 'bold 20px Nunito';"

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = 'bold 50px Rubik';
            ctx.fillStyle = '#1C174F';
            // ctx.restore(); // Add if text with different typo


            for (var i = 0; i<lines.length; i++) {

              ctx.fillText(lines[i], x, y + (i*lineheight));
              if (i > 0) {
                var lineheight = (i + 1)*20;
              }
              eval(font_other);

            }
          },
        }


        const bottomleftTextDoughnut = {
          id: 'bottomleftDoughnut',
          afterDatasetsDraw(chart, args, options) {
            const { ctx, data, chartArea: {top, bottom, left, right, width, height} } = chart;

            if (ctx.canvas.dataset.to_bottom_left !== undefined) {
              var text = ctx.canvas.dataset.to_bottom_left
            } else {
              var text = ""
            }

            if (ctx.canvas.dataset.half_doughnut !== undefined) {
              var x = left;
              var y = bottom ;
            } else {
              var x = left;
              var y = bottom;
            }

            var lineheight = 10;
            var lines = text.split('\n');
            var font_other = "ctx.font = 'bold 20px Nunito';"

            // TO IMPROVE
            // ctx.canvas.dataset.to_center should look like that
            // [
            //   [text1, lineheight,["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text2, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text3, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ]
            // ]

            ctx.save();
            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            ctx.font = 'bold 1rem Rubik';
            ctx.fillStyle = "#f5557f";
            // ctx.restore(); // Add if text with different typo


            for (var i = 0; i<lines.length; i++) {

              ctx.fillText(lines[i], x, y + (i*lineheight));
              if (i > 0) {
                var lineheight = (i + 1)*20;
              }
              eval(font_other);

            }
          },
        }


        const bottomrightTextDoughnut = {
          id: 'bottomrightDoughnut',
          afterDatasetsDraw(chart, args, options) {
            const { ctx, data, chartArea: {top, bottom, left, right, width, height} } = chart;

            if (ctx.canvas.dataset.to_bottom_right !== undefined) {
              var text = ctx.canvas.dataset.to_bottom_right
            } else {
              var text = ""
            }

            if (ctx.canvas.dataset.half_doughnut !== undefined) {
              var x = right;
              var y = bottom ;
            } else {
              var x = right;
              var y = bottom;
            }

            var lineheight = 10;
            var lines = text.split('\n');
            var font_other = "ctx.font = 'bold 20px Nunito';"

            // TO IMPROVE
            // ctx.canvas.dataset.to_center should look like that
            // [
            //   [text1, lineheight,["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text2, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ],
            //   [text3, lineheight, ["ctx.font = 'bold 30px sans-serif';", "ctx.fillStyle = 'blue';"] ]
            // ]

            ctx.save();
            ctx.textAlign = 'right';
            ctx.textBaseline = 'middle';
            ctx.font = 'bold 1rem Rubik';
            ctx.fillStyle = '#51c7a0';
            // ctx.restore(); // Add if text with different typo


            for (var i = 0; i<lines.length; i++) {

              ctx.fillText(lines[i], x, y + (i*lineheight));
              if (i > 0) {
                var lineheight = (i + 1)*20;
              }
              eval(font_other);

            }
          },
        }


        var myChart = new Chart(ctx, {
          type: typeGraph,
          data: {
              labels: labels,
              datasets: datasets
          },
          options: {
            circumference: circumference,
            rotation: rotation,
            borderRadius: borderRadius,
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                }
              },
              tooltip: {
                enabled: (tooltipEnabled === false) ? false : true,
              }
            },
            layout: {
              padding: {
                bottom: 12
              }
            }
          },
          plugins: [centerTextDoughnut, bottomleftTextDoughnut, bottomrightTextDoughnut],
        })
      }
      else if(typeGraph === "bar") {

        var counter_stack = 0;
        let borderColorAll = [];
        var typeDataset = [];
        var display_legend_stack = (ctx.canvas.dataset.label_stack === undefined) ? "false" : ctx.canvas.dataset.label_stack;
        // nbElem not correct
        // const nbElem = JSON.parse(ctx.canvas.dataset.data).length;

        borderColorAll = JSON.parse(ctx.canvas.dataset.color);

        if (ctx.canvas.dataset.label === undefined) {
          JSON.parse(ctx.canvas.dataset.data).forEach((arrays) => {
            var counter_label = 0;
            typeDataset.push([]);
            arrays.forEach((array) => {
              typeDataset[counter_stack].push(ctx.canvas.dataset.type_dataset ? (typeof JSON.parse(ctx.canvas.dataset.type_dataset)?.[counter_stack]?.[counter_label] === 'undefined' ? typeGraph : JSON.parse(ctx.canvas.dataset.type_dataset)[counter_stack][counter_label]) : typeGraph);
              datasets.push(`{
                "data": [${array}],
                "type": "${typeDataset[counter_stack][counter_label]}",
                "stack": "Stack ${counter_stack.toString()}",
                "borderWidth": "${(display_legend_stack === "false") ? 0 : 2}",
                "borderSkipped": "middle",
                "borderColor": "${typeDataset[counter_stack][counter_label] === "line" ? borderColorAll[counter_stack][counter_label] : borderColor[counter_label]}",
                "backgroundColor": "${borderColorAll[counter_stack][counter_label]}"
              }`);
            counter_label = counter_label + 1;
            });
            counter_stack = counter_stack + 1;
          });
        } else  {
          JSON.parse(ctx.canvas.dataset.data).forEach((arrays) => {
            var counter_label = 0;
            typeDataset.push([]);

            arrays.forEach((array) => {
              typeDataset[counter_stack].push(ctx.canvas.dataset.type_dataset ? (typeof JSON.parse(ctx.canvas.dataset.type_dataset)?.[counter_stack]?.[counter_label] === 'undefined' ? typeGraph : JSON.parse(ctx.canvas.dataset.type_dataset)[counter_stack][counter_label]) : typeGraph);
              datasets.push(`{
                "data": [${array}],
                "type": "${typeDataset[counter_stack][counter_label]}",
                "label": "${JSON.parse(ctx.canvas.dataset.label)[counter_stack][counter_label]}",
                "stack": "Stack ${counter_stack.toString()}",
                "borderWidth": "${(display_legend_stack === "false") ? 0 : 2}",
                "borderSkipped": "middle",
                "borderColor": "${typeDataset[counter_stack][counter_label] === "line" ? borderColorAll[counter_stack][counter_label] : borderColor[counter_stack]}",
                "backgroundColor": "${borderColorAll[counter_stack][counter_label]}"
              }`);
              counter_label = counter_label + 1;
            });
            counter_stack = counter_stack + 1;
          });
        }

        datasets = datasets.map(dataset => JSON.parse(dataset));

        var myChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: JSON.parse(ctx.canvas.dataset.labels),
            datasets: datasets
          },
          options: {
            plugins: {
              legend: {
                display: (display_legend_stack === "false") ? true : false,
                position: 'right',
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            },
            responsive: true,
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                stacked: true,
                type: 'time',
                time: {
                  unit: ctx.canvas.dataset.time_unit,
                  displayFormats: {
                    week: "W",
                    day: 'ddd. D MMMM'
                  }
                },
                ticks: {
                  callback: function (value) {
                    if (ctx.canvas.dataset.time_unit === "week") {
                      return 'W' + value;
                    } else {
                      return value;
                    }
                  }
                }
              },
              y: {
                stacked: true
              }
            }
          }
        })

      }
    });
  }
}

export { chart }
