import $ from 'jquery';
import 'select2';
import { submitForm } from '../components/submit_form_ajax';

const select2 = () => {
  document.querySelectorAll('.select2').forEach(function(selectElement) {
    if (selectElement) {
      let dropdownParent = null;

      const modalParent = selectElement.closest('.modal');
      if (modalParent && modalParent.classList.contains('show')) {
        dropdownParent = modalParent;
      }

      const options = {
        theme: "bootstrap-5",
      };

      if (dropdownParent) {
        options.dropdownParent = dropdownParent;
      }

      $(selectElement).select2(options);

      // Check if the form parent has the class submit_form_ajax
      const form = selectElement.closest('form');
      if (form && form.classList.contains('js-submit-form-ajax')) {
        // Listen for event to submit the form after selection
        $(selectElement).on('change.select2', function() {
          const formId = form.id;
          const method = form.method.toUpperCase();
          submitForm(method, formId)
        });
      }
    }
  });
};

const setupModalListeners = () => {
  // Listen for event to display modals
  document.querySelectorAll('.modal').forEach(function(modal) {
    if (modal) { // Check if modal is not null
      modal.addEventListener('shown.bs.modal', function() {
        select2(modal); // Init select2 only in this modal
      });
    }
  });
};

document.addEventListener('DOMContentLoaded', function() {
  // Init for elements outside modal
  select2(document);

  // Config listeners for modals
  setupModalListeners();
});

export { select2, setupModalListeners };
