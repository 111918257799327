const listenClickBtn = () => {
  const buttons = document.querySelectorAll('.js-listen-click-btn');
  if (buttons) {
    sentLogClickBtnToMake(buttons);
  }
}

function sentLogClickBtnToMake(array) {
  array.forEach(function(button) {
    const query = button.dataset.query;

    button.addEventListener('click', function(event) {
      event.preventDefault();

      fetch(`https://hook.eu1.make.com/588b9tex8q104ogre9hb1ufks7blmnds?${query}`)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok " + response.statusText);
          }
          return response.text();
        })
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        });
    });
  });
}

export { listenClickBtn }
