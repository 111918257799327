const copyClipboardUrl = () => {
  if (document.querySelectorAll('.js-copy-link-button')) {
    const allViewsButtonLink = document.querySelectorAll('.js-copy-link-button')


    allViewsButtonLink.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        e.preventDefault()
        console.log(elem.dataset.toggle)
        const inputId = elem.dataset.toggle
        copy(inputId)

        elem.classList.add('btn-success')
        let timer = window.setTimeout( function() {
            let timer = null;
            elem.classList.remove('btn-success')
        }, 1000 );
      })
    })
  }
}

export { copyClipboardUrl }

function copy(id) {
  var copyText = document.querySelector(`${id}`)
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  // console.log(copyText.select())

  navigator.clipboard.writeText(copyText.value);

  document.querySelector(`${id}`).classList.add('btn-success')
  // document.execCommand("copy");

  // alert("Copied the text: " + copyText.value);
}
