import Sortable from 'sortablejs';

const sortLinkView = () => {
  if(document.querySelectorAll('.js-card-links')[0]) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var list = document.querySelectorAll('.js-card-links')[0];

    Sortable.create(list, {
      ghostClass: "ghost",
      handle: '.js-sortable',
      animation: 150,
      onEnd: (event) => {
        const linkId = event.item.dataset.link;

          fetch(`${window.location.origin}/links/${linkId}/sortable?order=${event.newIndex + 1}`, {
            method: 'PUT',
            headers: {
              'X-CSRF-Token': token,
            },
          })
          .then(response => response.text())
          .then(data => {
            document.getElementById('js-iframe').src = document.getElementById('js-iframe').src
          })
          .catch((error) => {
            console.error('Error:', error);
          });

        }

    });
  }
};

export { sortLinkView };
