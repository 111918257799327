import { Popover } from 'bootstrap';

const popover = () => {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl, {
      trigger: 'hover'
    })
  })
};

const setupModalListeners = () => {
  // TODO THOMAS
  // NOTE : DOESNT WORK WELL WITH
  // MODALS AFTER LOADING
  // MODAL WITH SCROLL
  document.querySelectorAll('.modal').forEach(modal => {
    modal.addEventListener('shown.bs.modal', () => {
      popover();
    });
  });
};

document.addEventListener('DOMContentLoaded', function() {
  popover(document);
  setupModalListeners();
});

export { popover, setupModalListeners };