const counterIncrementationLink = () => {
  if (document.querySelector(".js-link")) {
    const magnetId = document.querySelector('.js-counter_increment_link').dataset.magnet;
    const typeSource = document.querySelector('.js-counter_increment_link').dataset.type_source;
    const allLinks = document.querySelectorAll('.js-link');

    allLinks.forEach(link => {
      const linkId = link.dataset.target;

      link.addEventListener('click', function(event) {
        fetch(`/links/${linkId}/update-counter?magnet_id=${magnetId}&type_source=${typeSource}`, {
          method: 'PUT',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: linkId })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
        })
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
      });
    });
  }
}

export { counterIncrementationLink };
