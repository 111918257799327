// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import 'bootstrap';


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports

// Internal imports, e.g:
import { chart } from '../components/chart'
import { copyClipboardUrl } from '../components/copy_clipboard'
import { listenClickBtn } from '../components/listen_click_btn'
import { popover } from '../plugins/popover'
import { previewImageOnFileSelect } from '../components/photo_preview'
import { select2 } from '../plugins/select2'
import { sortLinkView } from '../components/sort_link_view'
import { submitFormAjax } from '../components/submit_form_ajax'
import { counterIncrementationLink } from '../components/counter_incrementation_link'

document.addEventListener('turbolinks:load', () => {
  chart();
  copyClipboardUrl();
  listenClickBtn();
  popover();
  previewImageOnFileSelect();
  select2();
  sortLinkView();
  submitFormAjax();
  counterIncrementationLink();
});

document.addEventListener('dom:updated', () => {
  submitFormAjax();
});
